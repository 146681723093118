import React, { useEffect, useState } from "react";
import Header from "../Layouts/Header";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from '@mui/material/Button';

function Top() {
  return (
    <section className="landing-page">
      <img
        className="solar-image img-fluid"
        style={{ width: "-webkit-fill-available", height: "90vh", filter: "opacity(0.9)" }}
        src="/images/solarlight.jpg"
        alt=""
      />
       <nav style={{
          position: "relative",
          top: "-530px",
        left: "1rem"
        
        }} class="navbar  d-inline">
          <a class="navbar-brand flo" href="#">
            <img
              style={{ width: "20%",  }}
              className=""
              src="/images/logowithtag.png"
              alt="logo-brand"
            />
          </a>
       
          <Button href="/dashboard/home" sx={{
            float: "right", 
            marginRight: "2rem",
            marginTop:"-2.2rem"
          }} className="mr-auto" variant="outlined">Log in</Button>
           <span style={{ float: "right", 
            marginRight: "2rem", marginTop:"-2.2rem"}}>Last Sync at : {new Date().toString().slice(0,24)}</span>
        </nav>
      <div
        style={{
          position: "relative",
          top: "-470px",
          color: "white",
          textAlign: "center",
        }}
      >
       

        <h2 style={{color : "#151D3B", fontWeight:"900", letterSpacing:"1.2px", fontSize:"2.5vw", marginLeft:"100px"}}>Mukhyamantri Gramin</h2>
        <h2 style={{color : "#151D3B", fontWeight:"900", letterSpacing:"1.2px", fontSize:"2.5vw"}}>Solar Street Light Yojna</h2>
        <FormControl
       
          variant="filled"
          sx={{ m: 1, minWidth: 120 }}
          style={{ width: "300px" }}
        >
          <InputLabel sx={{color:"black"}} id="demo-simple-select-filled-label">
            Select Site
          </InputLabel>
          <Select
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            // value={age}
            //   onChange={handleChange}
          >
            
            <MenuItem value={"value1"}>Saran</MenuItem>
            
          </Select>
        </FormControl>
      </div>
    </section>
  );
}

export default Top;
