import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import shoppingBagFill from '@iconify/icons-eva/shopping-bag-fill';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import lockFill from '@iconify/icons-eva/lock-fill';
import personAddFill from '@iconify/icons-eva/person-add-fill';
import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';
import ErrorIcon from '@mui/icons-material/Error';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import SupportIcon from '@mui/icons-material/Support';


// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/home',
    icon: getIcon(pieChart2Fill)
  },
  {
    title: 'Monitor',
    path: '/dashboard/monitor',
    icon: getIcon('icon-park:analysis')
  },
  {
    title: 'Project Progress',
    path: '/dashboard/performance',
    icon: <AutorenewIcon/>
  },
  {
    title: 'Incident Management',
    path: '/dashboard/incident',
    icon: <ErrorIcon/>
  },
  {
    title: 'Asset Management',
    path: '/dashboard/asset',
    icon: <DeviceHubIcon/>
  },
 
  {
    title: 'User Management',
    path: '/dashboard/users',
    icon: <SupervisedUserCircleIcon/>
  },
  
  
  
 
  
  
  
 
 
  
  {
    title: 'Helpdesk',
    path: '/dashboard/helpdesk',
    icon: <SupportIcon/>
  },
  {
    title: 'System Performance',
    path: '/dashboard/system-performance',
    icon: getIcon('eos-icons:performance')
  },
  {
    title: 'MIS Reporting',
    path: '/dashboard/report',
    icon: getIcon(fileTextFill)
  },


  // {
  //   title: 'OTA Updates',
  //   path: '/dashboard/report',
  //   icon: getIcon(fileTextFill)
  // },

];

export default sidebarConfig;
