import React from 'react'
import "./stylehelpdesk.scss"

function Helpdesk() {
  return (
    <>
    
  
  
  
  
 
  
  <div class="container"><hr/></div>
  
  <section class="little_boxes">
    <div class="container">
      <div class="row">
        <div class="col-md-3 col-sm-6">
          <a class="box" href="#">
            <span class="glyphicon glyphicon-plus" aria-hidden="true"></span>
            <h3>Create a helpdesk ticket</h3>
            <p>Submitting a ticket will result in a ticket being opened.</p>
          </a>
        </div>
        <div class="col-md-3 col-sm-6">
          <a class="box" href="#">
            <span class="glyphicon glyphicon-cloud" aria-hidden="true"></span>
            <h3>View tickets</h3>
            <p>Tickets in the cloud are actually on a server. The cloud is a metaphor.</p>
          </a>
        </div>
        <div class="col-md-3 col-sm-6">
          <a class="box" href="#">
            <span class="glyphicon glyphicon-signal" aria-hidden="true"></span>
            <h3>Request analytics</h3>
            <p>Analytics are numbers and data that help you.</p>
          </a>
        </div>
        <div class="col-md-3 col-sm-6">
          <a class="box" href="#">
            <span class="glyphicon glyphicon-alert" aria-hidden="true"></span>
            <h3>Report an issue</h3>
          </a>
        </div>
      </div>
    </div>
  </section>
  
  <div class="container"><hr/></div>
  
  <section class="stats">
    <div class="container">
      <div class="row">
        <div class="col-md-3 col-sm-6 text-center">
          <h3>Tickets Opened</h3>
          <span class="humungous">345</span>
        </div>      
        <div class="col-md-3 col-sm-6 html donut text-center">
          <h3>Tickets Completed</h3>
          <span>215</span>
          <svg width="160" height="160" xmlns="http://www.w3.org/2000/svg">
           <g>
            <title>Ticket Completion Rate</title>
            <circle id="circle" class="circle_animation" r="69.85699" cy="81" cx="81" stroke-width="10" fill="none"/>
           </g>
          </svg>
        </div>     
        <div class="col-md-6 text-center">
          <h3>Tickets Per Day</h3>
          <ul class="chart">
            <li>
              <span style={{height:"15%"}} title="M">15</span>
            </li>
            <li>
              <span style={{height:"70%"}}title="Tu">70</span>
            </li>
            <li>
              <span style={{height:"50%"}}title="W">50</span>
            </li>
            <li>
              <span style={{height:"25%"}}title="Th">25</span>
            </li>
            <li>
              <span style={{height:"16%"}} title="F">16</span>
            </li>
          </ul>    
        </div>
      </div>
    </div>
  </section>
  
  <div class="container"><hr/></div>
  
  <section class="little_boxes">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-sm-6">
          <a class="box sortadark" href="#">
            <span class="glyphicon glyphicon-flag icon-large" aria-hidden="true"></span>
            <h3>Flag an item</h3>
            <p>Flagging doesn't really do anything, but studies have shown that it makes people feel better.</p>
          </a>
        </div>
        <div class="col-md-6 col-sm-6">
          <a class="box dark" href="#">
            <span class="glyphicon glyphicon-comment icon-large" aria-hidden="true"></span>
            <h3>Chat with a rep</h3>
            <p>Click to chat with a Techical rep that can assist you.</p>
          </a>
        </div>      
      </div>
    </div>
  </section>
  
  <div class="container"><hr/></div>
  
  <section>
    <div class="container">
      <div class="col-md-6 col-sm-6">
        <div class="alert alert-success alert-dismissible" role="alert">
        <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        If you're <strong>happy</strong> and you know it, you'll get this alert.
        </div>

        <div class="alert alert-info alert-dismissible" role="alert">
        <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <strong>Heads up!</strong> This lil guy needs your attention, but it's not super-important.
        </div>
      </div>
      <div class="col-md-6 col-sm-6">
        <div class="alert alert-warning alert-dismissible" role="alert">
        <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <strong>Warning!</strong> Check yourself, before you wreck yourself.
        </div>

        <div class="alert alert-danger alert-dismissible" role="alert">
        <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <strong>Danger!</strong> Seriously, I hope you know what you're doing. Things are red.
        </div>
      </div>
    </div>
  </section>
 
  <div class="container"><hr/></div>
  
  <section class="faq">
    <div class="container">
      <h2>Frequently Asked Questions (FAQs)</h2>
      <p class="question">How do I reset my password?</p>
      <p class="answer">Click on the Forgot your Password link and type in your User ID. Choose Send a temporary password to my email address. Answer your security question. Click - Next. Click - Done.        
      
      </p>
      
    </div>
  </section>
  
  <div class="container"><hr/></div>
  
 
  
  <div class="container"><hr/></div>
  
  <section class="little_boxes">
    <div class="container">
      
    </div>
  </section>
  
 

  
  
    </>
  )
}

export default Helpdesk