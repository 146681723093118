import * as React from "react";
import { useState, useEffect } from "react";
import Block from "./Block";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Panchayat from "./Panchayat";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";
import TableRowe from "./TableRowe";
import RechartLine from "./Voltage";
import * as mqtt from "mqtt"



// import Pdf from "react-to-pdf";
import { subDays, addDays } from "date-fns";
import LineBarChart from "./LineBarChart";
// var options = {
//   clientId: 'web-client',
//   connectTimeout: 5000,
//   hostname: '13.127.187.28',
//   port: 8080,
//     path: '/mqtt'
//   };

// let client = mqtt.connect(options)
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: "80vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  overflowY: "scroll",
  p: 4,
};

const ref = React.createRef();

function Report() {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(null);
  const [data, setData] = useState();
  const [showDiv, setShowDiv] = useState(false);
  const [district, setDistrict] = React.useState("10");
  const [panchayat, setPanchayat] = React.useState("10");
  const [block, setBlock] = React.useState("10");
  const [ward, setWard] = React.useState("10");
  const [device, setDevice] = React.useState("869343049931888");
  const [deviceType, setDeviceType] = useState("");
  const [reqData, setReqData] = useState([]);
  const [luminarys, setLuminarys] = useState("luminary");
  const [solars, setSolars] = useState("solar");
  const [batterys, setbattery] = useState("battery");
  const [vendor, setVendorType] = useState("");
  const [connectionStatus, setConnectionStatus] = React.useState(false);
  const [messages, setMessages] = React.useState([]);


  // useEffect(() => {
  //   const client = mqtt.connect(options);
  //   client.on('connect', function () {
  //   client.subscribe('presence', function (err) {
  //     if (!err) {
  //       client.publish('presence', 'Hello mqtt')
  //     }
  //   })
  // })
  //   client.on('message', (topic, payload, packet) => {
  //     setMessages(messages.concat(payload.toString()));
  //   });
  // }, []);

  
  // client.on('message', function (topic, message) {
  //   // message is Buffer
  //   console.log(message.toString())
  //   client.end()
  // })

  const handleChangeDistrictSelector = (event) => {
    setDistrict(event.target.value);
  };
  const handleChangeDeviceSelector = (event) => {
    setDevice(event.target.value);
  };
  const handleChangeDeviceTypeSelector = (event) => {
    setDeviceType(event.target.value);
  };
  const handleChangeVendorTypeSelector = (event) => {
    setVendorType(event.target.value);
  };
  const handleChangeBlockSelector = (event) => {
    setBlock(event.target.value);
  };
  const handleChangePanchayatSelector = (event) => {
    setPanchayat(event.target.value);
  };
  const handleChangeWardSelector = (event) => {
    setWard(event.target.value);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // console.log(deviceType);
  useEffect(() => {
    console.log(deviceType);
    var res = startDate.toISOString().substring(0, 10);

    var rese = endDate.toISOString().substring(0, 10);

    axios
      .get(
        `https://helios.coredataworld.com/api/${deviceType}/${res}/${rese}`
      )
      .then((response) => {
        console.log(response.data);
        setReqData(response.data);
        setShowDiv(true);
      });
  }, [deviceType, startDate, endDate, solars, batterys]);

  function createTablerow(singleData, index) {
    // var date1 = new Date(singleData.ts.slice(0, 19) + " UTC");
    // var ws = date1.toString();
    // var ds = ws.slice(0, 24);

    const time = singleData.timestamp;
    var d = new Date(time);
    var w = d.toString().slice(0, 24);

    return (
      <TableRowe
        key={singleData.uuid}
        in={index + 1}
        // imei={singleData.access_token}
        id={singleData.uuid}
        time={w}
        reason={singleData.reason}
      />
    );
  }

  return (
    <>
      <div className="container-fluid mt-3">
        <Typography
          sx={{ fontWeight: "600" }}
          id="modal-modal-title"
          variant="h4"
          component="h4"
          className="text mb-4 h4 colorssl"
        >
          Report Location:-
        </Typography>
        {/* {messages.map((message) => (
        <h2>{message}</h2>))} */}
     

        <Box sx={{ flexDirection: "row" }}>
          <div className="control-content">
            <div className="row ">
              <div className="col-lg-2">
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-autowidth-label">
                    District
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={district}
                    onChange={handleChangeDistrictSelector}
                    autoWidth
                    label="District"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Saran</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-lg-2 mx-4">
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Block
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={block}
                    onChange={handleChangeBlockSelector}
                    autoWidth
                    label="District"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Sadar</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-lg-2 mx-2">
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Panchayat
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={panchayat}
                    onChange={handleChangePanchayatSelector}
                    autoWidth
                    label="District"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Naini</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-lg-2 mx-3">
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Ward
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={ward}
                    onChange={handleChangeWardSelector}
                    autoWidth
                    label="Ward"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>WARD-12</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-lg-1 mx-3">
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Device
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={device}
                    onChange={handleChangeDeviceSelector}
                    autoWidth
                    label="Device"
                  >
                    <MenuItem value={869343049931888}>
                      BIHSARS
                    </MenuItem>
                    <MenuItem value={869343049932027}>Local</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <Typography
            sx={{ fontWeight: "600" }}
            id="modal-modal-title"
            variant="h4"
            component="h4"
            className="text mb-4 h4 colorssl"
          >
            Select Vendor:-
          </Typography>
          <div className="row">
            <div className="col-lg-6">
              <FormControl sx={{ m: 1, minWidth: 150 }}>
                <InputLabel id="demo-simple-select-autowidth-label">
                  Select
                </InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={vendor}
                  onChange={handleChangeVendorTypeSelector}
                  autoWidth
                  label="Device"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={1}>Executing Agency 1</MenuItem>
                  <MenuItem value={2}>Executing Agency 2</MenuItem>
                  <MenuItem value={3}>Executing Agency 3</MenuItem>
                  <MenuItem value={4}>Executing Agency 4</MenuItem>
                </Select>
              </FormControl>
            </div>
            
              <div className="col-lg-6">
                
              <FormControl sx={{ m: 1, minWidth: 150 }}>
                <InputLabel id="demo-simple-select-autowidth-label">
                  Select
                </InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={deviceType}
                  onChange={handleChangeDeviceTypeSelector}
                  autoWidth
                  label="Device"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={luminarys}>Luminary Fault</MenuItem>
                  <MenuItem value={solars}>Solar Fault</MenuItem>
                  <MenuItem value={batterys}>Battery Fault</MenuItem>
                </Select>
              </FormControl>
        
            </div>
          </div>
          
          <div className="row">
            
          </div>

          {/* Second Row */}
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              justifyContent: "center",
              padding: "1% 15%",
              backgroundColor: "white",
            }}
          >
            <Grid item xs={4}>
              <label for="birthday">From:</label>
              <DatePicker
                selected={startDate}
                excludeDateIntervals={[
                  {
                    start: subDays(new Date("2021/02/08"), 0),
                    end: addDays(new Date("2022/01/15"), 0),
                  },
                ]}
                onChange={(date) => setStartDate(date)}
              />
            </Grid>
            <Grid item xs={4}>
              <label for="birthday">To: </label>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
              />
            </Grid>
            {/* <Grid item xs={6}>
              <Button  className="mt-3" variant="contained">
                Get Here
              </Button>

              <Pdf targetRef={ref} filename="panic-report.pdf">
                {({ toPdf }) => (
                  <Button
                    variant="contained"
                    className="mx-4 mt-3"
                    onClick={toPdf}
                  >
                    Pdf
                  </Button>
                )}
              </Pdf>
              <Button className="mt-3" variant="contained">
                Excel
              </Button>
            </Grid> */}
          </Grid>

          {/* Table */}
          <div ref={ref}>
            <Grid className="mt-4 mb-4" conatainer spacing={2}>
              <table id="table-to-xls" class="download-table-xls-button table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Unique Device ID</th>

                    {/* <th scope="col">IMEI</th> */}
                    <th scope="col"> Occurence </th>
                    <th scope="col"> Reason </th>
                  </tr>
                </thead>
                <tbody>{showDiv ? reqData.map(createTablerow) : null}</tbody>
              </table>
            </Grid>

            {/* Graph */}
            <Grid container={2}></Grid>
          </div>
        </Box>
        {/* <LineBarChart /> */}
      </div>
    </>
  );
}

export default Report;
