import React from "react";
import { Chart } from "react-google-charts";

export const data = [
  ["Week", "Light On", "Light Off", "Signal Loss"],
  ["Week-1", 100, 40, 20],
  ["Week-2", 150, 30, 25],
  ["Week-3", 60, 110, 30],
  ["Week-4", 103, 54, 35],
];

export const options = {
  chart: {
    title: "Tracking Data",
    subtitle: "Weekly",
  },
};

export function LineChart() {
  return (
    <Chart
      chartType="Bar"
      width="100%"
      height="400px"
      data={data}
      options={options}
    />
  );
}
