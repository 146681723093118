import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import SmallCard from "./SmallCard";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 430,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function TransitionsModal4(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <div className="row mt-4">
        <div style={{ width: "400px" }} className="col">
          <SmallCard feature={props.f1} values={props.v1} />
        </div>
        {
          props.f2 && <div style={{ width: "400px" }} className="col">
          <SmallCard feature={props.f2} values={props.v2} />
        </div>
        }

      </div>
    </div>
  );
}
