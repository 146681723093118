import React, { useEffect, useState } from "react";

function Bottom() {
  return (
    <section className="landing-page">
      <img
        className="solar-image"
        style={{ width: "-webkit-fill-available", height: "70vh", position:"relative", top:"-250px" }}
        src="/images/sl.jpg"
        alt=""
      />
      <div>
           
           <div style={{position :"relative", top:"-100px"}}  className="footer text-center">
               <span> &copy; CoreData Networks 2022 | All Rights Reserved. &nbsp; &nbsp; <b>FAQ</b>&nbsp;&nbsp; <b>Repot an issue</b></span>
           </div>
         
           </div>
    </section>
  );
}

export default Bottom;
