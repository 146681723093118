import React from 'react'
import DataTable from './Monitor/DataTable'
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CollapsibleTable from './CollapsibleTable';

function AssetManagement() {
  const [district, setDistrict] = React.useState("10");
  const [panchayat, setPanchayat] = React.useState("10");
  const [block, setBlock] = React.useState("10");
  const [ward, setWard] = React.useState("10");
  const [device, setDevice] = React.useState("869343049932027");

  const handleChangeDistrictSelector = (event) => {
    setDistrict(event.target.value);
  };

  const handleChangeBlockSelector = (event) => {
    setBlock(event.target.value);
  };
  const handleChangePanchayatSelector = (event) => {
    setPanchayat(event.target.value);
  };
  const handleChangeWardSelector = (event) => {
    setWard(event.target.value);
  };
  const handleChangeDeviceSelector = (event) => {
    setDevice(event.target.value);
   
  };

  return (
    <>
    <div style={{ marginBottom: "45px" }} className="row ">
            <div className="col-lg-1 col-sm-4 ">
              <FormControl sx={{ m: 1, minWidth: 150 }}>
                <InputLabel id="demo-simple-select-autowidth-label">
                  District
                </InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={district}
                  onChange={handleChangeDistrictSelector}
                  autoWidth
                  label="District"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Saran</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-lg-1 col-sm-4">
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-autowidth-label">
                  Block
                </InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={block}
                  onChange={handleChangeBlockSelector}
                  autoWidth
                  label="District"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Sadar</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-lg-1 col-sm-4">
              <FormControl sx={{ m: 1, minWidth: 150 }}>
                <InputLabel id="demo-simple-select-autowidth-label">
                  Panchayat
                </InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={panchayat}
                  onChange={handleChangePanchayatSelector}
                  autoWidth
                  label="District"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Naini</MenuItem>
                </Select>
              </FormControl>
            </div>
            {/* <div className="col-lg-1 col-sm-3">
              <FormControl sx={{ m: 1, minWidth: 100 }}>
                <InputLabel id="demo-simple-select-autowidth-label">
                  Ward
                </InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={ward}
                  onChange={handleChangeWardSelector}
                  autoWidth
                  label="Ward"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>WARD-12</MenuItem>
                </Select>
              </FormControl>
            </div> */}
            {/* <div className="col-lg-1 col-sm-4 ">
              <FormControl sx={{ m: 1, maxWidth: 150 }}>
                <InputLabel id="demo-simple-select-autowidth-label">
                  Device
                </InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={device}
                  onChange={handleChangeDeviceSelector}
                  autoWidth
                  label="Device"
                >
                  <MenuItem value={869343049931888}>Local</MenuItem>
                  <MenuItem value={869343049932027}>BIHSARSADNAI1201</MenuItem>
                </Select>
              </FormControl>
            </div> */}
           
            
          </div>
    <h4 className='colorssl mb-4'>Device Inventory:-</h4>
    < CollapsibleTable />
    {/* <DataTable  imei="8735353646467" ts="July 31 2022 12:38:10"/> */}
    </>
  )
}

export default AssetManagement