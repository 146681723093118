import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
function TableRowe(props) {
  return <>
   <tr>
      <th scope="row">{props.in}</th>
      <td>{props.id}</td>
      
      
      {/* <td>{props.imei}</td> */}
      <td>{props.time}</td>
      <td>{props.reason}</td>
      
     
    </tr>
  </>;
}

export default TableRowe;
