import React from 'react';

function SmallCard(props) {

    
  return <>
  <div
          class="card shadow colorssl  text-center"
          style={{ width: props.width }}
        >
          <div class="card-header fw-bold">  {props.feature} </div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item"> {props.values} </li>
          </ul>
        </div>
  </>;
}

export default SmallCard;
