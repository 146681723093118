import React, { useEffect, useState } from "react";
// import DonutChart from "react-donut-chart";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

function Donut() {
  return (
    <>
    <div className="mj">
      
  
    <div className="row">
      <div className="col-lg-6 text-center">  
      <h3 className="header-text dd">Total RMS : 1</h3>
      </div>
      <div className="col-lg-6">  
      <h3 className="header-text mx-4">Total Street Lights : 1</h3>
      </div>

      </div>
     
      
   
    <section className="landing-page header-text">
     
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          className="text-center"
        >
          {/* <Grid item xs={6}>
            <DonutChart
              height="400"
              width="500"
              innerRadius="0.6"
              data={[
                {
                  label: "Signal Loss",
                  value: 0,
                },
                {
                  label: "RMS OFF",
                  value: 0,
                },
                {
                  label: "RMS ON",
                  value: 1,
                },
              ]}
            />
          </Grid>
          <Grid item xs={6}>
         
            <DonutChart
              height="400"
              width="500"
              innerRadius="0.6"
              data={[
                {
                  label: "Signal Loss",
                  value: 0,
                },
                {
                  label: "RMS OFF",
                  value: 0,
                },
                {
                  label: "RMS ON",
                  value: 1,
                },
              ]}
            />
          </Grid> */}
        </Grid>
      </Box>
    </section>
    </div>
    </>
  );
}

export default Donut;
