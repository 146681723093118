import * as React from "react";
import { useEffect } from "react";
import { styled } from "@mui/system";
// import { TablePagination } from '@mui/base';
// import TablePaginationUnstyled from "@mui/base";
import axios from "axios";
// import DonutChart from "react-donut-chart";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Page from "../../components/Page";

function createData(
  name,
  calories,
  fat,
  siteCode,
  timeOfCall,
  callDuration,
  callMode
) {
  return { name, calories, fat, siteCode, timeOfCall, callDuration, callMode };
}

const blue = {
  200: "#A5D8FF",
  400: "#3399FF",
};

const grey = {
  50: "#F3F6F9",
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const Root = styled("div")(
  ({ theme }) => `
  table {
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
    text-align: left;
    padding: 6px;
  }

  th {
    background-color: ${theme.palette.mode === "dark" ? grey[900] : grey[100]};
  }
  `
);

// const CustomTablePagination = styled(TablePagination)(
//   ({ theme }) => `
//   & .MuiTablePaginationUnstyled-spacer {
//     display: none;
//   }
//   & .MuiTablePaginationUnstyled-toolbar {
//     display: flex;
//     flex-direction: column;
//     align-items: flex-start;
//     gap: 10px;

//     @media (min-width: 768px) {
//       flex-direction: row;
//       align-items: center;
//     }
//   }
//   & .MuiTablePaginationUnstyled-selectLabel {
//     margin: 0;
//   }
//   & .MuiTablePaginationUnstyled-select {
//     padding: 2px;
//     border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
//     border-radius: 50px;
//     background-color: transparent;
//     &:hover {
//       background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
//     }
//     &:focus {
//       outline: 1px solid ${theme.palette.mode === "dark" ? blue[400] : blue[200]
//     };
//     }
//   }
//   & .MuiTablePaginationUnstyled-displayedRows {
//     margin: 0;

//     @media (min-width: 768px) {
//       margin-left: auto;
//     }
//   }
//   & .MuiTablePaginationUnstyled-actions {
//     padding: 2px;
//     border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
//     border-radius: 50px;
//     text-align: center;
//   }
//   & .MuiTablePaginationUnstyled-actions > button {
//     margin: 0 8px;
//     border: transparent;
//     border-radius: 2px;
//     background-color: transparent;
//     &:hover {
//       background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
//     }
//     &:focus {
//       outline: 1px solid ${theme.palette.mode === "dark" ? blue[400] : blue[200]
//     };
//     }
//   }
//   `
// );

export default function UnstyledTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [arr, setArr] = React.useState([]);
  const [district, setDistrict] = React.useState("10");
  const [panchayat, setPanchayat] = React.useState("10");
  const [block, setBlock] = React.useState("10");
  const [ward, setWard] = React.useState("10");
  const [device, setDevice] = React.useState("869343049932027");
  
  const handleChangeDeviceSelector = (event) => {
    setDevice(event.target.value);

  };

  const handleChangeDistrictSelector = (event) => {
    setDistrict(event.target.value);
  };

  const handleChangeBlockSelector = (event) => {
    setBlock(event.target.value);
  };
  const handleChangePanchayatSelector = (event) => {
    setPanchayat(event.target.value);
  };
  const handleChangeWardSelector = (event) => {
    setWard(event.target.value);
  };

  useEffect(() => {
    console.log('entered');
    const d = new Date();
    var t = d.toISOString().split("T")[0];
    console.log(t);
    console.log(d);
    axios
      .get(
        "https://helios.coredataworld.com/incident"
      )
      .then((response) => {
        console.log(response.data);
        const w = response.data.reverse()
        setArr(w)
        // let dataOfCalls = response.data.data;
        // const newArray = dataOfCalls
        //   .slice(Math.max(dataOfCalls.length - 5, 0))
        //   .reverse();
        // console.log(newArray);
        // setArr(newArray);
      });
  }, []);

  const rows = arr;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Page title="Dashboard | Incident Management">
        <div className="row">
          <h4 className="colorssl">
            Device Incidents:-
          </h4>
        </div>
        <div style={{ marginBottom: "5vh", marginTop: "3vh" }} className="row ">
          <div className="col-lg-1 col-sm-2  me-3">
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-autowidth-label">
                District
              </InputLabel>
              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={district}
                onChange={handleChangeDistrictSelector}
                autoWidth
                label="District"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Saran</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-1 col-sm-4 mx-4">
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-autowidth-label">
                Block
              </InputLabel>
              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={block}
                onChange={handleChangeBlockSelector}
                autoWidth
                label="District"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Sadar</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-1 col-sm-4 mx-3">
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-autowidth-label">
                Panchayat
              </InputLabel>
              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={panchayat}
                onChange={handleChangePanchayatSelector}
                autoWidth
                label="District"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Naini</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-1 col-sm-4 mx-4">
            <FormControl sx={{ m: 1, minWidth: 100 }}>
              <InputLabel id="demo-simple-select-autowidth-label">
                Ward
              </InputLabel>
              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={ward}
                onChange={handleChangeWardSelector}
                autoWidth
                label="Ward"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>WARD-12</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-1 col-sm-4 mx-3">
            <FormControl sx={{ m: 1, maxWidth: 100 }}>
              <InputLabel id="demo-simple-select-autowidth-label">
                Device
              </InputLabel>
              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={device}
                onChange={handleChangeDeviceSelector}
                autoWidth
                label="Device"
              >
                <MenuItem value={869343049931888}>Local</MenuItem>
                <MenuItem value={869343049932027}>BIHSARSADNAI1201</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-4"></div>
        </div>
        {/* <div className="row mt-4">
          <div className="col-md-6 text-center">
            <p>Device Name : <b>BIHSARSADNAI1201</b> </p>
            <DonutChart
              height="300"
              width="360"
              innerRadius="0.3"
              data={[
                {
                  label: "Open",
                  value: 1,
                },
                {
                  label: "Progress",
                  value: 3,
                },
                {
                  label: "Resolved",
                  value: 1,
                },
              ]}
            />
          </div>
          <div className="col-md-6 text-center">
            <p>Current Status : <b style={{ "color": "green" }}>HEALTHY</b></p>
            <DonutChart
              height="300"
              width="360"
              innerRadius="0.3"
              option={{
                responsive: true,
                maintainAspectRatio: true,
              }}
              data={[
                {
                  label: "Routine",
                  value: 1,
                },
                {
                  label: "Maintenance",
                  value: 0,
                },
                {
                  label: "Outage",
                  value: 0,
                },
              ]}
            />
          </div>
        </div> */}
        <Root sx={{ maxWidth: "100%" }}>
          <table aria-label="custom pagination table">
            <thead>
              <tr>
                <th>Fault ID</th>
                <th>Device</th>
                <th>Fault Reason</th>
                <th>Fault Condition</th>
                <th>Fault Time</th>
                <th>Status</th>

              </tr>
            </thead>
            <tbody>
              {(rowsPerPage > 0
                ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : rows
              ).map((row) => {
                const time = row.fault_time
                var d = new Date(time)
                var w = d.toString().slice(0, 24)
                return (
                  <tr key={row.name}>
                    <td style={{ width: 200 }}>{row.uuid.slice(0, 26)}</td>
                    <td style={{ width: 120 }} align="right">
                      {row.device}
                    </td>
                    <td style={{ width: 120 }} align="right">
                      {row.reason}
                    </td>
                    <td style={{ width: 120 }} align="right">
                      {row.fault_condition}
                    </td>
                    <td style={{ width: 120 }} align="right">
                      {w}
                    </td>

                    <td style={{ width: 120 }} align="right">
                      {row.status}
                    </td>
                  </tr>
                );
              })}

              {emptyRows > 0 && (
                <tr style={{ height: 41 * emptyRows }}>
                  <td colSpan={3} />
                </tr>
              )}
            </tbody>
            <tfoot>
              {/* <tr>
                <CustomTablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={7}
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  componentsProps={{
                    select: {
                      "aria-label": "rows per page",
                    },
                    actions: {
                      showFirstButton: true,
                      showLastButton: true,
                    },
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </tr> */}
            </tfoot>
          </table>
        </Root>
      </Page>
    </>
  );
}

