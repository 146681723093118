import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
function Header() {
  const [isOpen, setIsopen] = useState(false);

  const ToggleSidebar = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
  };
  return (
    <>
      <div className="container-fluid mt-3">
        <nav className="navbar navbar-expand-lg navbar-light bg-white shadow-md">
          <div className="container-fluid p-2">
            <div className="logo-div">
              <a className="navbar-brand  text-primary mr-0">
                <img
                  className="logo-dashboard"
                  src="/images/logowithtag.png"
                  alt="brand-logo"
                />
              </a>
              <span className=" deepshadow colorssl fw-bold text-center h6 ">
              Helios
              </span>
            </div>
            <div className="form-inline ml-auto">
              <div className="btn btn-primary" onClick={ToggleSidebar}>
                <i className="fa fa-bars"></i>
              </div>
            </div>
            {/* <Stack direction="row" spacing={2}>
              <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
            </Stack> */}
          </div>
        </nav>

        <div className={`sidebar ${isOpen == true ? "active" : ""}`}>
          <div className="sd-header">
            <h4 className="mb-0 colorssl">SSL-RMS</h4>
            <div className="btn btn-primary" onClick={ToggleSidebar}>
              <i className="fa fa-times"></i>
            </div>
          </div>
          <div className="sd-body">
            <ul>
              <li>
                <a href="/dashboard" className="sd-link">
                  Home
                </a>
              </li>
              <li>
                <a href="/monitor" className="sd-link">
                  Monitor
                </a>
              </li>
              <li>
                <a href="control" className="sd-link">
                  Control
                </a>
              </li>
              <li>
                <a href="report" className="sd-link">
                  Report
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div
          className={`sidebar-overlay ${isOpen == true ? "active" : ""}`}
          onClick={ToggleSidebar}
        ></div>
      </div>
    </>
  );
}

export default Header;
