import React from 'react'
import ReactDOM from 'react-dom';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits } from 'react-instantsearch-dom';

const searchClient = algoliasearch(
    'TM2DH6G3PU',
    '44f8936b6a4f4810bdbeec42dbd359f0'
  );

function InstantSearchs() {
  return (
    <InstantSearch
    indexName="cms"
    searchClient={searchClient}
  >
    <SearchBox />
    <Hits />
  </InstantSearch>
  )
}

export default InstantSearch