import React from "react";

function ValueCard(props) {
  return (
    <>
      <div id="value-card" class="card">
        <div class="card-body nn  shadow-md">
          <div className="img-responsive mb-2">
            {/* <span> <b>100%</b> </span> */}
          <img className="rounded-bottom m-auto" style={{"width":"35%", "position":"relative", "top":"-60px"}} src={props.img} alt="battery" />
          </div>
        <div style={{"position":"relative", "top":"-60px"}} className="abc">
        <p className="list-group-item colorssl">VOLTAGE &nbsp;: <b className="fontvalue">&nbsp; &nbsp; &nbsp; {props.voltage} V</b> </p>
          <p className="list-group-item colorssl">CURRENT &nbsp;: <b className="fontvalue">&nbsp; &nbsp; &nbsp; {props.current} A</b> </p>
          <p className="list-group-item  colorssl">POWER &nbsp; &nbsp; &nbsp;: <b className="fontvalue">&nbsp; &nbsp; &nbsp; {props.power} W</b> </p>
          <br />
          <p className="list-group-item  colorssl">{props.value3} &nbsp;: &nbsp; &nbsp; <b>{props.current_fault}</b> </p>
          <p className="list-group-item colorssl">FAULT DATE & TIME : <b> {props.last_fault} </b>  </p>
          <p className="list-group-item  colorssl">FAULT RECTIFICATION : <b>{props.fault_rectification}</b>  </p>
          <p className="list-group-item  colorssl">{props.name} : <b>{props.value} {props.name2} {props.value2} </b>  </p>
        </div>
 
          {/* <p className="fontvalue">
            {" "}
            15 W
          </p> */}
        </div>
      </div>
    </>
  );
}

export default ValueCard;
