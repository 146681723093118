import React from 'react'
import SearchBar from "material-ui-search-bar";

function SearchBarComponent() {
  return (
    <>
    <SearchBar
    value={this.state.value}
    onChange={(newValue) => this.setState({ value: newValue })}
  />
    </>
  )
}

export default SearchBarComponent