import React from "react";
import { Helmet } from "react-helmet";
import Top from "../../LandingPage/top";
import Icons from "../../LandingPage/icons";
import Donut from "../../LandingPage/donut"
import Bottom from "../../LandingPage/bottom";
import Footer from "../../Layouts/Footer"

function index() {
  return (
    <React.Fragment>
      <Helmet titleTemplate="SSL-CMS | %s" title="Home" />
      <Top />
      <Icons />
     <Donut />
      <Bottom />
  
    </React.Fragment>
  );
}

export default index;
