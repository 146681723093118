import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import ConstructionIcon from "@mui/icons-material/Construction";

const IconArray = [
  { icon: "/images/revenue.png", name: "Total Site" },
  { icon: ConstructionIcon, name: "Actual Load" },
  { icon: ConstructionIcon, name: "Total Energy Saving Per Year" },
  { icon: ConstructionIcon, name: "Revenue saved per year" },
  { icon: ConstructionIcon, name: "GHG Reduction per year" },
  { icon: ConstructionIcon, name: "Actual Capacity" },
];

function Icons() {
  return (
    <section
      className="landing-page m-auto"
      style={{ width: "70%", position: "relative", top: "-300px" }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
         
            <Grid  item xs={2} sm={4} md={4} >
              <div className="icon-box m-auto center-img text-center ">
                <img style={{"width":"40%"}} className="rounded-circle" src="/images/buildings.png" alt="" />
              </div>
              <br />
              <p className="text-center paratext" style={{ color: "#151D3B" }}>
                Total Site
              </p>
              <p className="text-center numbers">
                1
              </p>
            </Grid>
            <Grid  item xs={2} sm={4} md={4} >
              <div className="icon-box m-auto center-img text-center ">
                <img style={{"width":"40%"}} className="rounded-circle" src="/images/power-plant.png" alt="" />
              </div>
              <br />
              <p className="text-center paratext" style={{ color: "#151D3B" }}>
              Actual Load
              </p>
              <p className="text-center numbers" >
                5.67 kW
              </p>
            </Grid>
            <Grid  item xs={2} sm={4} md={4} >
              <div className="icon-box m-auto center-img text-center ">
                <img style={{"width":"40%"}} className="rounded-circle" src="/images/energy1.png" alt="" />
              </div>
              <br />
              <p className="text-center paratext" style={{ color: "#151D3B" }}>
              Total Energy Saving Per Year
              </p>
              <p className="text-center numbers" >
                144830.54 kWH
              </p>
            </Grid>
            <Grid  item xs={2} sm={4} md={4} >
              <div className="icon-box m-auto center-img text-center ">
                <img style={{"width":"40%"}} className="rounded-circle" src="/images/revee.png" alt="" />
              </div>
              <br />
              <p className="text-center paratext" style={{ color: "#151D3B" }}>
              Revenue saved per year
              </p>
              <p className="text-center numbers" >
                150.02 Crore
              </p>
            </Grid>
            <Grid  item xs={2} sm={4} md={4} >
              <div className="icon-box m-auto center-img text-center ">
                <img style={{"width":"40%"}} className="rounded-circle" src="/images/co2.png" alt="" />
              </div>
              <br />
              <p className="text-center paratext" style={{ color: "#151D3B" }}>
              GHG Reduction per year
              </p>
              <p className="text-center numbers">
                0.410 Million
              </p>
            </Grid>
            <Grid  item xs={2} sm={4} md={4} >
              <div className="icon-box m-auto center-img text-center ">
                <img style={{"width":"40%"}} className="rounded-circle" src="/images/electricity.png" alt="" />
              </div>
              <br />
              <p className="text-center paratext" style={{ color: "#151D3B" }}>
              Avoided Capacity
              </p>
              <p className="text-center numbers" >
                109.081 MW
              </p>
            </Grid>
          
        </Grid>
      </Box>
    </section>
  );
}

export default Icons;
