import React, { useState } from "react";
import AppContext from "./app-context";

function AppState(props) {
  const [blockId, setBlockId] = useState([])
  
  return (
    <AppContext.Provider
      value={{
        message: "This is from the context",blockId, setBlockId
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
}

export default AppState;
