import React from "react";
import { Helmet } from "react-helmet";
import {
  LoginPage,
  LoginLayout,
  RegistrationPage,
  CustomThemeProvider,
} from "../../Login/Login";

function index() {
  return (
    <React.Fragment>
      <Helmet titleTemplate="SSL-CMS | %s" title="Login" />
      <CustomThemeProvider>
      <LoginLayout>
        <LoginPage />
      </LoginLayout>
      </CustomThemeProvider>
    </React.Fragment>
  );
}

export default index;
