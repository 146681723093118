// import { Navigate, useRoutes } from 'react-router-dom';
// // layouts
// import DashboardLayout from './layouts/dashboard';
// import LogoOnlyLayout from './layouts/LogoOnlyLayout';
// //
// import LandingPage from './components/Screens/LandingPage'
// import Register from './pages/Register';
// import DashboardApp from './pages/DashboardApp';
// import Products from './pages/Products';
// import Blog from './pages/Blog';
// import User from './pages/User';
// import Users from './pages/Users';
// import NotFound from './pages/Page404';
// import Login from './components/Screens/Login'
// import Helpdesk from './pages/Helpdesk';
// import Performance from './pages/Performance';
// import AssetManagement from './pages/AssetManagement';
// import SystemPerformance from './pages/SystemPerformance';
// import UnstyledTable from './pages/Control/Control';

// // ----------------------------------------------------------------------

// export default function Router() {
//   return useRoutes([
//     {
//       path: '/', 
//       element: <Login />
//     },
//     {
//       path: '/dashboard',
//       element: <DashboardLayout />,
//       children: [
//         { element: <Navigate to="/dashboard/home" replace /> },
//         { path: 'home', element: <DashboardApp /> },
//         { path: 'monitor', element: <User /> },
//         { path: 'control', element: <Products /> },
//         { path: 'report', element: <Blog /> },
//         { path: 'users', element: <Users /> },
//         { path: 'helpdesk', element: <Helpdesk /> },
//         { path: 'incident', element: <UnstyledTable /> },
//         { path: 'performance', element: <Performance /> },
//         {path:'asset',element: <AssetManagement/> },
//         {path:'system-performance',element: <SystemPerformance/> }

        
//       ]
//     },
//     {
//       path: '/',
//       element: <LogoOnlyLayout />,
//       children: [
//         // { path: 'login', element: <Login /> },
//         { path: 'register', element: <Register /> },
//         { path: '404', element: <NotFound /> },
//         { path: '/', element: <Navigate to="/dashboard" /> },
//         { path: '*', element: <Navigate to="/404" /> }
//       ]
//     },
    
//     {
//       path: '/landing', 
//       element: <LandingPage />
//     },
//     { path: '*', element: <Navigate to="/404" replace /> }
//   ]);
// }


import { Navigate, useRoutes } from 'react-router-dom';
import { useAuth } from './context/AuthContext';  // To access the current user
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
// pages
import LandingPage from './components/Screens/LandingPage'
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import Products from './pages/Products';
import Blog from './pages/Blog';
import User from './pages/User';
import Users from './pages/Users';
import NotFound from './pages/Page404';
import Login from './components/Screens/Login'
import Helpdesk from './pages/Helpdesk';
import Performance from './pages/Performance';
import AssetManagement from './pages/AssetManagement';
import SystemPerformance from './pages/SystemPerformance';
import UnstyledTable from './pages/Control/Control';

export default function Router() {
  const { currentUser } = useAuth();  // Get current user
  const user = localStorage.getItem('user'); // Check if the user is stored in localStorage

  return useRoutes([
    {
      path: '/', 
      element: user || currentUser ? <Navigate to="/dashboard/home" replace /> : <Login />
    },
    {
      path: '/dashboard',
      element: user || currentUser ? <DashboardLayout /> : <Navigate to="/" replace />,
      children: [
        { element: <Navigate to="/dashboard/home" replace /> },
        { path: 'home', element: <DashboardApp /> },
        { path: 'monitor', element: <User /> },
        { path: 'control', element: <Products /> },
        { path: 'report', element: <Blog /> },
        { path: 'users', element: <Users /> },
        { path: 'helpdesk', element: <Helpdesk /> },
        { path: 'incident', element: <UnstyledTable /> },
        { path: 'performance', element: <Performance /> },
        { path: 'asset', element: <AssetManagement /> },
        { path: 'system-performance', element: <SystemPerformance /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/dashboard" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    {
      path: '/landing', 
      element: <LandingPage />
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
