// material
import { Box, Grid, Container, Typography } from "@mui/material";
import { LineChart } from "./LineChart";
import MapDriver from "./MapDriver";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import React, { useState, useEffect } from "react";

// components
import Page from "../components/Page";
import {
  AppTasks,
  AppNewUsers,
  AppBugReports,
  AppItemOrders,
  AppNewsUpdate,
  AppWeeklySales,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppCurrentSubject,
  AppConversionRates,
} from "../components/_dashboard/app";

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const [district, setDistrict] = React.useState("10");
  const [panchayat, setPanchayat] = React.useState("10");
  const [block, setBlock] = React.useState("10");
  const [ward, setWard] = React.useState("10");
  const [device, setDevice] = React.useState("869343049932027");

  const handleChangeDeviceSelector = (event) => {
    setDevice(event.target.value);
    
  };
  
  const handleChangeDistrictSelector = (event) => {
    setDistrict(event.target.value);
  };

  const handleChangeBlockSelector = (event) => {
    setBlock(event.target.value);
  };
  const handleChangePanchayatSelector = (event) => {
    setPanchayat(event.target.value);
  };
  const handleChangeWardSelector = (event) => {
    setWard(event.target.value);
  };

  return (
    <>
     <Page title="Dashboard">
    <div className="container-fluid bg-white">
    <div style={{ marginBottom: "5vh" }} className="row ">
            <div className="col-lg-1 col-sm-2  me-3">
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-autowidth-label">
                  District
                </InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={district}
                  onChange={handleChangeDistrictSelector}
                  autoWidth
                  label="District"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Saran</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-lg-1 col-sm-4 mx-4">
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-autowidth-label">
                  Block
                </InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={block}
                  onChange={handleChangeBlockSelector}
                  autoWidth
                  label="District"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Sadar</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-lg-1 col-sm-4 mx-3">
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-autowidth-label">
                  Panchayat
                </InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={panchayat}
                  onChange={handleChangePanchayatSelector}
                  autoWidth
                  label="District"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Naini</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-lg-1 col-sm-4 mx-4">
              <FormControl sx={{ m: 1, minWidth: 100 }}>
                <InputLabel id="demo-simple-select-autowidth-label">
                  Ward
                </InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={ward}
                  onChange={handleChangeWardSelector}
                  autoWidth
                  label="Ward"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>WARD-12</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-lg-1 col-sm-4 mx-3">
              <FormControl sx={{ m: 1, maxWidth: 100 }}>
                <InputLabel id="demo-simple-select-autowidth-label">
                  Device
                </InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={device}
                  onChange={handleChangeDeviceSelector}
                  autoWidth
                  label="Device"
                >
                  <MenuItem value={869343049931888}>Local</MenuItem>
                  <MenuItem value={869343049932027}>BIHSARSADNAI1201</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-lg-4"></div>
          </div>
      <div className="row mt-2">
        <div className="col-lg-3 col-sm-3">
          <div className="card">
            <div className=" shadow-md card-body card-1">
              <p className="fw-bolder"> TOTAL STREET LIGHT</p>
              <p>2</p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-3">
          <div className="card">
            <div className="shadow-md card-body card-2">
              <p>TOTAL RMS</p>
              <p>2</p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-3">
          <div className="card">
            <div className="shadow-md card-body card-3">
              <p>INACTIVE STREET LIGHT</p>
              <p>1</p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-3">
          <div className="card">
            <div className=" shadow-md card-body card-4">
              <p>INACTIVE RMS</p>
              <p>1</p>
            </div>
          </div>
        </div>
      </div>
      <div className="map-and-chart  shadow-md p-1 mt-1">
      <div className="row mt-4">
          <MapDriver />
        </div> 
        <div className="row mt-4">
          <LineChart />
        </div>
        
      </div>
    </div>
    </Page>
    </>

  );
}
