import axios from "axios";
import React, { useEffect, useState } from "react";
import { PureComponent } from "react";
import {
  AreaChart,
  Area,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LineChart,
  ResponsiveContainer,
  Legend,
} from "recharts";

export default function Current(props) {
  
  return (
    <>
      
      
      <AreaChart
        width={1000}
        height={250}
        data={props.data}
        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#5FD068" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#5FD068" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#6a67ce" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#6a67ce" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorSv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#85F4FF" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#85F4FF" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis tick={false} dataKey="name" />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Area
          type="monotone"
          dataKey="Solar_current"
          stroke="#5FD068"
          fillOpacity={1}
          fill="url(#colorUv)"
        />
        <Area
          type="monotone"
          dataKey="Battery_current"
          stroke="#6a67ce"
          fillOpacity={1}
          fill="url(#colorPv)"
        />
        <Area
          type="monotone"
          dataKey="Luminary_current"
          stroke="#85F4FF"
          fillOpacity={1}
          fill="url(#colorSv)"
        />
      </AreaChart>
    </>
  );
}
