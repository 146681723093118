import faker from 'faker';
import { sample } from 'lodash';
// utils
import { mockImgAvatar } from '../utils/mockImages';

// ----------------------------------------------------------------------

const users = [...Array(24)].map((_, index) => ({
  id: faker.datatype.uuid(),
  avatarUrl: mockImgAvatar(index + 1),
  name: sample([
    'Ajay K Singh',
    'P Rao',
    'S Bhan',
    'W shah',
    'Amrit Jha',
    'Shubh Jaiswal',
    'P Singh',
    'A Khan',
    'R. Shergil',
    'S Kumar',
    'Raman jain',
    'D pal', 
    'S. Kirti',
    'C K Roy',
    'D Sinha',
    'Vijay K Singh',
    'R Rao',
    'D Bhan',
    'G shah',
    'Amar Jha',
    'Shubhi Jaiswal',
    'Praveen Singh',
    'Azaz Khan',
    'Razzaq Shergil',
    'Shiv Kumar',
    'Raman jain',
    'Dharmendra pal', 
    'Sunil Kirti',
    'B K Roy',
    'Deepak Sinha',

  ]),
  Vendor: sample([
    'Executing Agency 1',
    'PRD',
    'BREDA',
    'Executing Agency 2',
    'Executing Agency 3',
    'Executing Agency 4',
    'Executing Agency 5',
    'Executing Agency 6',
    'Executing Agency 7',
    'Executing Agency 8',
    'Executing Agency 9',
    'Executing Agency 10'
  ]),
  isVerified: faker.datatype.boolean(),
  status: sample(['active', 'banned']),
  role: sample([
    'User',
    'Admin',
    'Super-Admin',
    
    
  ])
}));

export default users;
