import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import faker from 'faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);






export function Vertical(props) {
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top' 
          },
          title: {
            display: true,
            text: props.title,
          },
        },
      };
    const labels = [props.ts];

     const data = {
        labels,
        datasets: [
          {
            label: props.p1,
            data: [props.sv],
            backgroundColor: '#5FD068',
          },
          {
            label: props.p2,
            data: [props.bv],
            backgroundColor: 'rgba(106, 103, 206)', 
          },
          {
            label: props.p3,
            data: [props.lv],
            
            backgroundColor: '#85F4FF',
          },
        ],
      };
  return <Bar options={options} data={data} />;
}
