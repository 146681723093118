import React, { useState, useEffect } from "react";
// import Weather from "./Weather";
import { io } from "socket.io-client";
import SmallCard from "./SmallCard";
import ValueCard from "./ValueCard";
import TransitionsModal from "./ButtonModal";
import DataTable from "./DataTable";
import Block from "../Report/Block";
import Panchayat from "../Report/Panchayat";
import TransitionsModal5 from "./five";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TransitionsModal1 from "./one";
import axios from "axios";
import Lines from "./LineChart";
import { Line } from "recharts";
import { Vertical } from "./Vertical";
import VerticalTabs from "./Tab";
import TransitionsModal2 from "./Two";
import TransitionsModal4 from "./Four";
import Map from "../Map";
import Button from "@mui/material/Button";
import ca from "date-fns/esm/locale/ca/index.js";
import Grid from "@mui/material/Grid";
import DatePicker from "react-datepicker";
import { subDays, addDays } from "date-fns";
import RechartLine from "../Report/Voltage";
import Power from "../Report/Power";
import Current from "../Report/Current";
import Voltage from "../Report/Voltage";
import moment from "moment";
import DataCard from "./DataCard";
import SearchBarComponent from "./SearchBar";
import InstantSearchs from "./InstantSearch";

function Monitor() {
  const [isLuminaryOn, setIsLuminaryOn] = useState(false);
  const [color, setColor] = useState(false);
  const [startDate, setStartDate] = useState(new Date("2024/10/01"));
  const [endDate, setEndDate] = useState(new Date());
  const [status, setStatus] = useState("off");
  const [panelVoltage, setPanelVoltage] = useState("");
  const [batteryVoltage, setBatteryVoltage] = useState("");
  const [loadVoltage, setLoadVoltage] = useState("");
  const [panelCurrent, setPanelCurrent] = useState("");
  const [batteryCurrent, setBatteryCurrent] = useState("");
  const [loadCurrent, setLoadCurrent] = useState();
  const [time, setTime] = useState("");
  const [panelFaultTime, setPanelFaultTime] = useState("");
  const [batteryFaultTime, setBatteryFaultTime] = useState("");
  const [loadFaultTime, setLoadFaultTime] = useState("");
  const [district, setDistrict] = React.useState("10");
  const [panchayat, setPanchayat] = React.useState("10");
  const [block, setBlock] = React.useState("10");
  const [ward, setWard] = React.useState("10");
  const [device, setDevice] = React.useState("866557054039054");
  const [rssi, setRssi] = useState("");
  const [imei, setImei] = useState("");
  const [loadPower, setLoadPower] = useState("");
  const printRef = React.useRef();
  const [ts, setTs] = useState();
  const [lat, setLat] = useState("awaiting data");
  const [lon, setLon] = useState("awaiting data");
  const [solarData, setSolarData] = useState("awaiting data");
  const [data, setData] = useState([]);
  const [showDiv, setShowDiv] = useState(false);
  const [status1, setStatus1] = useState(false);
  const [panelFault, setPanelFault] = useState(false);
  const [batteryFault, setbatteryFault] = useState(false);
  const [luminaryFault, setluminaryFault] = useState(false);
  const [ad, setAd] = useState();
  const [luminaryOnTime, setLuminaryOnTime] = useState("");
  const [luminaryOffTime, setLuminaryOffTime] = useState("");
  const [batteryPercent, setBatteryPercent] = useState("");
  const [pf, setPf] = useState(false);
  const [socketData, setSocketData] = useState("");
  const [voltage, setvoltage] = useState([]);
  const [power, setpower] = useState([]);
  const [current, setcurrent] = useState([]);
  const [temp, setTemp] = useState("");
  const [description, setDescription] = useState("");
  const [weatherIcon, setWeatherIcon] = useState("");
  const [solarFaultRectification, setSolarFaultRectification] = useState("")
  const [batteryFaultRectification, setBatteryFaultRectification] = useState("")
  const [luminaryFaultRectification, setLuminaryFaultRectification] = useState("")



  const handleChangeDeviceSelector = (event) => {
    setDevice(event.target.value);
    callApi();
  };

  useEffect(() => {

    callApi();
  }, [device])

  // useEffect(()=>{

  //   setInterval(() => {
  //     callApi();
  //     }, 10000);
  // },[])


  function callApi() {
    axios
      .get(`https://helios.coredataworld.com/telemetry/${device}`)
      // .get(`http://localhost:9002/telemetry/${device}`)
      .then((response) => {
        const time = response.data.ts;
        var d = new Date(time);
        var w = d.toString().slice(4, 24);
        const dataApi = response.data;
        const input = dataApi.bv;
        const min = 11.8;
        const max = 13.8;
        var result = ((input - min) * 100) / (max - min);
        const solar_voltage = parseFloat(dataApi.sv);

        const load_power = parseFloat(dataApi.lw);
        if (load_power > 0) {
          setIsLuminaryOn(true);
        }
        else {
          setIsLuminaryOn(false);

        }
        var hr = new Date().getHours();
        const isDayTime = hr > 6 && hr < 20;
        const isNightTime = hr > 20 || hr < 6;
        setTs(w);
        setLoadPower(load_power);
        setImei(dataApi.id);
        // if (dataApi.id === "866557054039054") {
        //   setColor(true);
        // }
        setPanelVoltage(dataApi.sv);
        setPanelCurrent(dataApi.sc);
        setBatteryCurrent(dataApi.bc);
        setBatteryVoltage(dataApi.bv);
        setBatteryPercent(Math.round(result));
        setRssi(dataApi.rssi);
        setAd(dataApi.sv);
        //luminary fault
        if (load_power < 9 && isNightTime) {
          setluminaryFault(true);
        } else {
          setluminaryFault(false);
        }
        if (solar_voltage < 5 && load_power > 9) {
          setLoadVoltage("18");
        } else {
          setLoadVoltage("0");
        }
        //panel fault
        if (solar_voltage < 1 && isDayTime) {
          setPanelFault(true);
        } else {
          setPanelFault(false);
        }
        if (parseFloat(dataApi.bv) < 1) {
          setbatteryFault(true);
        } else {
          setbatteryFault(false);
        }
        checkTimeDiff(w);

      });
  }

  const checkTimeDiff = (lastActiveTime) => {
    const given_ts = new Date(lastActiveTime).getTime()
    const current_time = new Date().getTime()
    if (current_time - given_ts <= 30 * 60 * 1000) {
      setColor(true)
    }
    else {
      setColor(false)
    }
  }



  const date1 = new Date("2022-06-03 06:51:55");
  const date2 = new Date("2022-06-03 06:36:16");
  const diffTime = date1 - date2;
  const diffDays = Math.floor(Math.abs(diffTime / (1000 * 3600 * 24)));
  // console.log("time de", (diffTime / 1000 / 60) % 24);
  // console.log("time difference", diffDays);
  useEffect(() => {
    var res = startDate.toISOString().substring(0, 10);

    var rese = endDate.toISOString().substring(0, 10);
    // console.log(res);
    // console.log(startDate);
    const roundDecimalUptoTwoPlaces = (number) => {
      return Math.round(number * 100) / 100;
    };

    const headers = {
      'Content-Type': 'application/json'

    }

    // axios.post("https://helios.coredataworld.com:7086/search_requests/", {
    //   "name": "Madhuri Dixit",
    //   "imagefile":
    //     "https://images.news18.com/ibnlive/uploads/2022/03/madhuri-dixit-1-1.jpg",
    //   "events":
    //     "https://coredata-content.s3.ap-southeast-1.amazonaws.com/2.jpg,https://pbs.twimg.com/profile_images/1477984690661314561/cKwqyIuN_400x400.jpg,https://coredata-content.s3.ap-southeast-1.amazonaws.com/3.jpeg,https://static.theprint.in/wp-content/uploads/2022/06/shah_rukh_khan_main_image20220605115333.jpg,https://st1.bollywoodlife.com/wp-content/uploads/2015/04/shahrukh-khan1.jpg,https://www.pinkvilla.com/files/styles/amp_metadata_content_image/public/madhuri-dixit-home-image-3.jpeg",
    // }, headers).then((response)=>{
    //   console.log(response.data);
    // })

    axios
      // .get(`https://helios.coredataworld.com/graph/voltage/${res}/${rese}`)
      // .get(`https://helios.coredataworld.com/graph/voltage_data/${res}/${rese}/${device}`)
      .get(`https://helios.coredataworld.com/graph/voltage_data/${res}/${rese}/${device}`)
      .then((response) => {
        setvoltage(response.data);
        const newArrayOfObj = response.data.map(
          ({
            sv: Solar_voltage,
            bv: Battery_voltage,
            ts: name,
            lv: Luminary_voltage,
            ...rest
          }) => ({
            Solar_voltage,
            Battery_voltage,
            name,
            Luminary_voltage,
            ...rest,
          })
        );

        const q = newArrayOfObj.map((v) => {
          var d = new Date(v.name);

          var w = d.toString().slice(0, 21);
          var f = d.toString().slice(8, 11);
          // console.log(f);
          // console.log(v.name);
          return {
            Solar_voltage: v.Solar_voltage,
            Battery_voltage: v.Battery_voltage,
            name: w,
            Luminary_voltage: v.Luminary_voltage,
          };
        });

        setvoltage(q);
      });

    axios
      .get(
        "https://api.openweathermap.org/data/2.5/weather?q=ghaziabad&appid=94e7bcdcfeccd1adcbf34e1a6d47d097&units=metric"
      )
      .then((response) => {
        // console.log(response.data);
        setTemp(response.data.main.temp);
        setDescription(response.data.weather[0].description);
        setWeatherIcon(response.data.weather[0].icon);
      });

    axios
      // .get(`https://helios.coredataworld.com/graph/current_data/${res}/${rese}/${device}`)
      .get(`https://helios.coredataworld.com/graph/current_data/${res}/${rese}/${device}`)
      .then((response) => {
        setcurrent(response.data);
        const newArrayOfObj = response.data.map(
          ({
            sc: Solar_current,
            bc: Battery_current,
            ts: name,
            lc: Luminary_current,
            ...rest
          }) => ({
            Solar_current,
            Battery_current,
            name,
            Luminary_current,
            ...rest,
          })
        );

        const q = newArrayOfObj.map((v) => {
          var d = new Date(v.name);
          var w = d.toString().slice(0, 21);
          var t = roundDecimalUptoTwoPlaces(v.Luminary_current);
          var u = roundDecimalUptoTwoPlaces(v.Solar_current);
          var v = roundDecimalUptoTwoPlaces(v.Battery_current);
          return {
            Solar_current: u,
            Battery_current: v,
            name: w,
            Luminary_current: t,
          };
        });

        setcurrent(q);
      });
    axios
      // .get(`https://helios.coredataworld.com/graph/power_data/${res}/${rese}/${device}`)
      .get(`https://helios.coredataworld.com/graph/power_data/${res}/${rese}/${device}`)
      .then((response) => {
        // console.log("range", response.data);

        const newArrayOfObj = response.data.map(
          ({
            sw: Solar_power,
            bw: Battery_power,
            ts: name,
            lw: Luminary_power,
            ...rest
          }) => ({
            Solar_power,
            Battery_power,
            name,
            Luminary_power,
            ...rest,
          })
        );

        const q = newArrayOfObj.map((v) => {
          var d = new Date(v.name);
          var w = d.toString().slice(0, 21);
          var t = roundDecimalUptoTwoPlaces(v.Luminary_power);
          var u = roundDecimalUptoTwoPlaces(v.Solar_power);
          var v = roundDecimalUptoTwoPlaces(v.Battery_power);
          return {
            Solar_power: u,
            Battery_power: v,
            name: w,
            Luminary_power: t,
          };
        });

        setpower(q);
      });

    // axios.get("https://helios.coredataworld.com/solar_fault").then((response) => {
    axios.get(`https://helios.coredataworld.com/solar_fault_new/${device}`).then((response) => {
      const time = response.data.timestamp;
      var d = new Date(time);
      var w = d.toString().slice(0, 24);
      setPanelFaultTime(w);
    });
    // axios.get("https://helios.coredataworld.com/battery_fault").then((response) => {
    axios.get(`https://helios.coredataworld.com/battery_fault_new/${device}`).then((response) => {
      const time = response.data.timestamp;
      var d = new Date(time);
      var w = d.toString().slice(0, 24);
      setBatteryFaultTime(w);
    });
    // axios.get("https://helios.coredataworld.com/fault_rectification/solar").then((response) => {
    axios.get(`https://helios.coredataworld.com/fault_rectification/solar/${device}`).then((response) => {
      const time = response.data.timestamp;
      var d = new Date(time);
      var w = d.toString().slice(0, 24);
      setSolarFaultRectification(w);
    });
    // axios.get("https://helios.coredataworld.com/fault_rectification/battery").then((response) => {
    axios.get(`https://helios.coredataworld.com/fault_rectification/battery/${device}`).then((response) => {
      const time = response.data.timestamp;
      var d = new Date(time);
      var w = d.toString().slice(0, 24);
      setBatteryFaultRectification(w);
    });
    // axios.get(`https://helios.coredataworld.com/fault_rectification/luminary/${device}`).then((response) => {
    axios.get(`https://helios.coredataworld.com/fault_rectification/luminary/${device}`).then((response) => {
      const time = response.data.timestamp;
      var d = new Date(time);
      var w = d.toString().slice(0, 24);
      setLuminaryFaultRectification(w);
    });
    // axios.get("https://helios.coredataworld.com/luminary_fault").then((response) => {
    axios.get(`https://helios.coredataworld.com/luminary_fault_new/${device}`).then((response) => {
      const time = response.data.timestamp;
      var d = new Date(time);
      var w = d.toString().slice(0, 24);
      setLoadFaultTime(w);
    });
    // axios.get(`https://helios.coredataworld.com/luminary_on_time_new/${device}`).then((response) => {
    axios.get(`https://helios.coredataworld.com/luminary_on_time_new/${device}`).then((response) => {
      const time = response.data.timestamp;
      var d = new Date(time);
      var w = d.toString().slice(0, 24);
      setLuminaryOnTime(w);
    });
    axios
      // .get(`https://helios.coredataworld.com/luminary_off_time_new/${device}`)
      .get(`https://helios.coredataworld.com/luminary_off_time_new/${device}`)
      .then((response) => {
        const time = response.data.timestamp;
        var d = new Date(time);
        var w = d.toString().slice(0, 24);
        setLuminaryOffTime(w);
      });

    const socket = io("https://helios.coredataworld.com:4001");

    socket.on("connect", () => {
      console.log(socket.id); // x8WIv7-mJelg7on_ALbx

      socket.on("readDat", (msg) => {
        var d = msg.slice(1, -1);
        const weed = JSON.parse(d);
        console.log("socket data", weed);
        setImei(weed.id);
        setPanelVoltage(weed.sv);
        setPanelCurrent(weed.sc);
        setBatteryCurrent(weed.bc);
        setBatteryVoltage(weed.bv);
        const input = weed.bv;
        const min = 11.9;
        const max = 13.8;
        var result = ((input - min) * 100) / (max - min);

        setBatteryPercent(Math.round(result));
        setRssi(weed.rssi);

        setAd(weed.sv);
        const solar_voltage = parseFloat(weed.sv);

        const load_power = parseFloat(weed.lw);

        setLoadPower(load_power);

        //luminary fault
        var hr = new Date().getHours();
        const isDayTime = hr > 6 && hr < 20;
        const isNightTime = hr > 20 || hr < 6;
        if (load_power < 9 && isNightTime) {
          setluminaryFault(true);
        } else {
          setluminaryFault(false);
        }

        if (solar_voltage < 5 && load_power > 9) {
          setLoadVoltage("18");
        } else {
          setLoadVoltage("0");
        }
        //panel fault
        if (solar_voltage < 1) {
          setPanelFault(true);
        } else {
          setPanelFault(false);
        }
        if (parseFloat(weed.bv) < 1) {
          setbatteryFault(true);
        } else {
          setbatteryFault(false);
        }
        const convertToRealTime = (a) => {
          var d = new Date(Math.round(a));
          const dateString = d.toLocaleString();
          return dateString;
        };
        const you = convertToRealTime(weed.ts * 1000);

        setTs(you);
      });
    });
  }, [startDate, endDate, device]);

  const handleChangeDistrictSelector = (event) => {
    setDistrict(event.target.value);
  };

  const handleChangeBlockSelector = (event) => {
    setBlock(event.target.value);
  };
  const handleChangePanchayatSelector = (event) => {
    setPanchayat(event.target.value);
  };
  const handleChangeWardSelector = (event) => {
    setWard(event.target.value);
  };

  const convertToRealTime = (a) => {
    var d = new Date(Math.round(a));
    const dateString = d.toLocaleString();
    return dateString;
  };
  console.log(device);
  return (
    <>
      <div className="container-fluid ">
        {/* <h1 className="h3 colorssl">Monitor :-</h1> */}
        <div className="">
          <div style={{ marginBottom: "45px" }} className="row ">
            <div className="col-lg-1 col-sm-4 ">
              <FormControl sx={{ m: 1, minWidth: 150 }}>
                <InputLabel id="demo-simple-select-autowidth-label">
                  District
                </InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={district}
                  onChange={handleChangeDistrictSelector}
                  autoWidth
                  label="District"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Saran</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-lg-1 col-sm-4">
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-autowidth-label">
                  Block
                </InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={block}
                  onChange={handleChangeBlockSelector}
                  autoWidth
                  label="District"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Sadar</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-lg-1 col-sm-4">
              <FormControl sx={{ m: 1, minWidth: 150 }}>
                <InputLabel id="demo-simple-select-autowidth-label">
                  Panchayat
                </InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={panchayat}
                  onChange={handleChangePanchayatSelector}
                  autoWidth
                  label="District"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Naini</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-lg-1 col-sm-3">
              <FormControl sx={{ m: 1, minWidth: 100 }}>
                <InputLabel id="demo-simple-select-autowidth-label">
                  Ward
                </InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={ward}
                  onChange={handleChangeWardSelector}
                  autoWidth
                  label="Ward"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>WARD-12</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-lg-1 col-sm-4 ">
              <FormControl sx={{ m: 1, maxWidth: 150 }}>
                <InputLabel id="demo-simple-select-autowidth-label">
                  Device
                </InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={device}
                  onChange={handleChangeDeviceSelector}
                  autoWidth
                  // minWidth='100px'
                  label="Device"
                >
                  <MenuItem value={866557054039054}>Local</MenuItem>
                  <MenuItem value={869343049932027}>BIHSARSADNAI1201</MenuItem>
                  <MenuItem value={861937064106991}>Demo</MenuItem>
                  <MenuItem value={861937064110324}>Demo1</MenuItem>
                  <MenuItem value={861937064115190}>Demo2</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div
              className={`mx-4 col-lg-1 mt-4 status-dot bg-${color ? "success" : "danger"
                }`}
            ></div>

          </div>
        </div>

        <div className=" mb-1">
          {/* <h1 className="d-inline-block colorssl">
            <i class=" fab fa-watchman-monitoring"></i>
          </h1> */}

          {/* <div class="status-page-link d-inline-block float-start">
            <div class="status-dot"></div>
          </div>
          <span className="colorssl float-end mt-3 fw-bold customfont">
            Time : {convertToRealTime(ts * 1000)}
           
          </span> */}

          {/* <span className="float-end colorssl fw-bold mt-3 px-2">
            System Status : Ok, RSSI : {rssi}{" "}
          </span>
          <span className="float-end colorssl fw-bold mt-3 px-2">
            IMEI : {imei}{" "}
          </span> */}
          {/* <span className="float-end colorssl fw-bold mt-3 px-2">
            SERIAL NUMBER : 8900035367484888{" "}
          </span> */}
        </div>

        {/* checking the UI    */}

        <div style={{ marginTop: "1000px" }} className="row mt-4">
          <div className="col-lg-3 col-sm-6">
            <div id="value-card" class="card">
              <div class="card-body nn  shadow-md">
                <div className="img-responsive">
                  <span style={{ fontSize: "0.7rem" }} className="mx-4 ">
                    <b>{temp} &deg;C</b>{" "}
                  </span>

                  <span style={{ fontSize: "0.7rem" }}>
                    <b>{description}</b>{" "}
                  </span>
                  <img
                    className="rounded-circle"
                    style={{
                      width: "40%",
                      position: "relative",
                      top: "-4.8rem",
                    }}
                    src={`http://openweathermap.org/img/wn/${weatherIcon}@2x.png`}
                    alt="battery"
                  />

                </div>
                <div
                  style={{ position: "relative", top: "-75px" }}
                  className="abc"
                >
                  <ul class="list-group fontchange colorssl">
                    {/* <li class="list-group-item shadow-sm mx-2 colorssl">
                      <Button onClick={callApi}>
                        <img
                          className="refresh"
                          src="/images/refresh-icon.png"
                          alt=""
                        />
                      </Button>
                    </li> */}
                    <li class="list-group-item shadow-sm mx-2 colorssl">
                      Updated Time : <b>{ts}</b>
                    </li>
                    <li class="list-group-item shadow-sm mx-2 colorssl">
                      Site Unique Code : <b>BIHSARSADNAI1201</b>
                    </li>
                    <li class="list-group-item shadow-sm mx-2 colorssl">
                      Address : <b>Bihar, Saran, Sadar, Naini, ward 12</b>
                    </li>

                    <li class="list-group-item shadow-sm mx-2 colorssl">
                      {" "}
                      IMEI : <b>{imei}</b>{" "}
                    </li>
                    <li class="list-group-item shadow-sm mx-2 colorssl">
                      S.No. : <b>8900035367484888</b>{" "}
                    </li>

                    <li class="list-group-item shadow-sm mx-2 colorssl">
                      RSSI : <b>{rssi}</b>{" "}
                    </li>
                  </ul>
                </div>

                {/* <p className="fontvalue">
            {" "}
            15 W
          </p> */}
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <ValueCard
              voltage={panelVoltage}
              current={panelCurrent}
              power={Math.floor(panelCurrent * panelVoltage)}
              name="INTESITY"
              value="Moderate"
              value3="MODULE FAULT"
              img="/images/solar-panel.png"
              current_fault={panelFault ? "Yes" : "No"}
              // last_fault="Mon Sep 05 2022 18:45:17"
              last_fault={panelFaultTime}
              // fault_rectification="Mon Sep 05 2022 20:00:17"
              // fault_rectification="Mon Sep 05 2022 20:00:17"
              fault_rectification={solarFaultRectification}
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <ValueCard
              voltage={batteryVoltage}
              current={batteryCurrent}
              power={Math.round(batteryCurrent * batteryVoltage * 100) / 100}
              name="CHARGE"
              value3="BATTERY FAULT"
              value={`${batteryPercent} %`}
              name2=", DOD : "
              value2={`${100 - batteryPercent} %`}
              img="/images/accumulator.png"
              current_fault={batteryFault ? "Yes" : "No"}
              last_fault={batteryFaultTime}
              fault_rectification={batteryFaultRectification}
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <ValueCard
              voltage={loadVoltage}
              current={Math.floor((loadPower / loadVoltage) * 100) / 100 || 0}
              power={loadPower}
              current_fault={luminaryFault ? "Yes" : "No"}
              // last_fault="Tue Sep 04 2022 07:58:10"
              img={isLuminaryOn ? "/images/light_on.png" : "/images/light_off.png"}
              name="STATUS"
              value3="SSL FAULT"
              value={isLuminaryOn ? "ON" : "OFF"}
              last_fault={loadFaultTime}
              // fault_rectification=" Tue Sep 04 2022 07:58:26"
              fault_rectification={luminaryFaultRectification}
            />
          </div>

          {/* <div className="col-lg-3">
            <div style={{"height":"54.3vh"}} className=" ">
              <Vertical
                title="Voltage"
                p1="Solar"
                p2="Battery"
                p3="Luminary"
                sv={ad}
                bv={batteryVoltage}
                lv={loadVoltage}
                ts={ts}
              />
              
            </div>
          </div> */}
        </div>

        {/* checking the UI    */}

        {/* <div className="text-center otherparameters">
          <TransitionsModal5
            f1="Brightness Level"
            f2="Full Brightness Hour"
            f3="Half Brightness Hour"
            f4="Battery State of Charge"
            f5="Battery Depth of Charge(in temp.)"
            mdf={panelVoltage === "0" ? "Yes" : "No"}
            bf={batteryVoltage === "0" ? "Yes" : "No"}
            sf={loadVoltage === "0" ? "Yes" : "No"}
            bfd={
              batteryVoltage === "0"
                ? convertToRealTime(batteryFaultTime)
                : "NA"
            }
            mfd={
              panelVoltage === "0" ? convertToRealTime(panelFaultTime) : "NA"
            }
            sfd={"NA"}
            buttonDetails="Brightness & Battery other details"
          />

          
        </div> */}
        <div className="row mt-4">
          <div className="col-lg-4">
            <div style={{ height: "54.3vh" }} className="shadow-md">
              <Vertical
                title="Voltage"
                p1="Solar"
                p2="Battery"
                p3="Luminary"
                sv={ad}
                bv={batteryVoltage}
                lv={loadVoltage}
                ts={ts}
              />
            </div>
          </div>
          <div className="col-lg-4">
            <div style={{ height: "54.3vh" }} className="shadow-md">
              <Vertical
                title="Current"
                p1="Solar"
                p2="Battery"
                p3="Luminary"
                sv={panelCurrent}
                bv={batteryCurrent}
                lv={Math.floor((loadPower / loadVoltage) * 100) / 100 || 0}
                ts={ts}
              />
            </div>
          </div>
          <div className="col-lg-4">
            <div style={{ height: "54.3vh" }} className="shadow-md">
              <Vertical
                title="Power"
                p1="Solar"
                p2="Battery"
                p3="Luminary"
                sv={ad * panelCurrent}
                bv={batteryVoltage * batteryCurrent}
                lv={loadPower}
                ts={ts}
              />
            </div>
          </div>
        </div>
        <div className="row mt-4 mb-4">
          <VerticalTabs
            ggg={luminaryOnTime}
            luminary_on_time={luminaryOnTime}
            luminary_off_time={luminaryOffTime}
            brightness_hour={loadPower > 9 ? "100%" : "0%"}
          />
        </div>

        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            padding: "1% 15%",
            backgroundColor: "white",
          }}
        >
          <Grid item xs={4}>
            <label for="birthday">From:</label>
            <DatePicker
              selected={startDate}
              excludeDateIntervals={[
                {
                  start: subDays(new Date("2021/02/08"), 0),
                  end: addDays(new Date("2022/01/15"), 0),
                },
              ]}
              onChange={(date) => setStartDate(date)}
            />
          </Grid>
          <Grid item xs={4}>
            <label for="birthday">To: </label>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
            />
          </Grid>
          <Grid item xs={2}>
            {/* <Button className="mt-3" variant="contained">
                Get Here
              </Button>  */}
          </Grid>
        </Grid>

        <div className="row text-center">
          <div className="col shadow-sm  text-center">
            <span>Voltage</span>
            <Voltage data={voltage} />
          </div>
          <div className="col shadow-sm mt-4 text-center">
            <span>Current</span>
            <Current data={current} />
          </div>
          <div className="col shadow-sm text-center">
            <span>Power</span>
            <Power data={power} />
          </div>
        </div>
        {/* <h1 className="colorssl mt-4">Total Device List :- </h1> */}
        {/* <Lines /> */}
        {/* <DataTable imei={imei} ts={ts} /> */}
        {/* <div className="er"></div> */}

        {/* <Map /> */}
      </div>
    </>
  );
}

export default Monitor;
