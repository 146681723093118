import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { Card, CardHeader, Box } from '@mui/material';
//
import { BaseOptionChart } from '../../charts';

// ----------------------------------------------------------------------

const CHART_DATA = [
  {
    name: 'Executing Agency 1',
    type: 'column',
    data: [2300, 1176, 2292, 2793, 1300, 2228, 3712, 2113, 4409, 2274, 3013]
  },
  {
    name: 'Executing Agency 2',
    type: 'area',
    data: [4408, 5534, 4111, 6765, 2223, 4311, 2195, 4134, 5682, 2765, 4312]
  },
  {
    name: 'Executing Agency 3',
    type: 'line',
    data: [3012, 2587, 3665, 3090, 4542, 3598, 6400, 5211, 5974, 3622, 3911]
  }
];

export default function AppWebsiteVisits() {
  const chartOptions = merge(BaseOptionChart(), {
    stroke: { width: [0, 2, 3] },
    plotOptions: { bar: { columnWidth: '11%', borderRadius: 4 } },
    fill: { type: ['solid', 'gradient', 'solid'] },
    labels: [
      '01/01/2022',
      '02/01/2022',
      '03/01/2022',
      '04/01/2022',
      '05/01/2022',
      '06/01/2022',
      '07/01/2022',
      '08/01/2022',
      '09/01/2022',
      '10/01/2022',
      '11/01/2022'
    ],
    xaxis: { type: 'datetime' },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)} device installation`;
          }
          return y;
        }
      }
    }
  });

  return (
    <Card>
      <CardHeader title="Executing Agency - Wise Installation" subheader="(+43%) than last month" />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart type="line" series={CHART_DATA} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}
