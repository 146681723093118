// // import React, { useState, useEffect } from "react";
// // import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
// // import "./Map.css";

// // import { Icon } from "leaflet";


// // // const AnyReactComponent = ({ text }) => <div>{text}</div>;

// // const features = [
// //     {
// //       "type": "Red",
// //       "properties": {
// //         "PARK_ID": 960,
// //         "FACILITYID": 28014,
// //         "NAME": "Lodhi Colony",
// //         "NAME_FR": "Planchodrome Bearbrook",
// //         "ADDRESS": "8720 Russell Road",
// //         "ADDRESS_FR": "8720, chemin Russell",
// //         "FACILITY_T": "flat",
// //         "FACILITY_1": "plat",
// //         "ACCESSCTRL": "no/non",
// //         "ACCESSIBLE": "no/non",
// //         "OPEN": null,
// //         "NOTES": "Outdoor",
// //         "MODIFIED_D": "2018/01/18",
// //         "CREATED_DA": null,
// //         "FACILITY": "Neighbourhood : smaller size facility to service population of 10,000 or less",
// //         "FACILITY_F": "De voisinage : petite installation assurant des services à 10 000 résidents ou moins.",
// //         "DESCRIPTIO": "Flat asphalt surface, 5 components",
// //         "DESCRIPT_1": "Surface d'asphalte plane, 5 modules",
// //         "PICTURE_LI": null,
// //         "PICTURE_DE": null,
// //         "PICTURE__1": null
// //       },
// //       "geometry": {
// //         "type": "Point",
// //         "coordinates": [25.765150, 84.783840]
// //       }
// //     },
// //     {
// //       "type": "Green",
// //       "properties": {
// //         "PARK_ID": 1219,
// //         "FACILITYID": 28001,
// //         "NAME": "Rampur",
// //         "NAME_FR": "Planchodrome Bob-MacQuarrie (Parc SK8 Extreme)",
// //         "ADDRESS": "1490 Youville Drive",
// //         "ADDRESS_FR": "1490, promenade Youville",
// //         "FACILITY_T": "other",
// //         "FACILITY_1": "autre",
// //         "ACCESSCTRL": "no/non",
// //         "ACCESSIBLE": "no/non",
// //         "OPEN": null,
// //         "NOTES": "Outdoor",
// //         "MODIFIED_D": "2018/01/18",
// //         "CREATED_DA": null,
// //         "FACILITY": "Community: mid size facility to service population of 40,000 plus",
// //         "FACILITY_F": "Communautaire : installation de taille moyenne assurant des services à 40 000 résidents ou plus.",
// //         "DESCRIPTIO": "Status: No connection",
// //         "DESCRIPT_1": "",
// //         "PICTURE_LI": null,
// //         "PICTURE_DE": null,
// //         "PICTURE__1": null
// //       },
// //       "geometry": {
// //         "type": "Point",
// //         "coordinates": [25.7643, 84.904610]
// //       }
// //     },
// //     {
// //       "type": "Orange",
// //       "properties": {
// //         "PARK_ID": 1157,
// //         "FACILITYID": 28002,
// //         "NAME": "Naini Chhapra",
// //         "NAME_FR": "Planchodrome Walter-Baker",
// //         "ADDRESS": "100 Charlie Rogers Place",
// //         "ADDRESS_FR": "100, place Charlie Rogers",
// //         "FACILITY_T": "bowl",
// //         "FACILITY_1": "bol",
// //         "ACCESSCTRL": "no/non",
// //         "ACCESSIBLE": "no/non",
// //         "OPEN": null,
// //         "NOTES": "Outdoor",
// //         "MODIFIED_D": "2018/01/18",
// //         "CREATED_DA": null,
// //         "FACILITY": "Community : mid size facility to service population of 40,000 plus",
// //         "FACILITY_F": "Communautaire : installation de taille moyenne assurant des services à 40 000 résidents ou plus.",
// //         "DESCRIPTIO": "Status : Healthy",
// //         "DESCRIPT_1": "Bol de béton, 7 000 pi2",
// //         "PICTURE_LI": null,
// //         "PICTURE_DE": null,
// //         "PICTURE__1": null
// //       },
// //       "geometry": {
// //         "type": "Point",
// //         "coordinates": [25.765150, 84.783840]
// //       }
// //     }
// //   ]

// // const redIcon = new Icon({
// //     iconUrl: "/greenssl.png",
// //     iconSize: [30, 30]
// // })

// // const greenIcon = new Icon({
// //     iconUrl: "/redssl.png",
// //     iconSize: [30, 30]
// // })

// // const orangeIcon = new Icon({
// //     iconUrl: "/greenssl.png",
// //     iconSize: [30, 30]
// // })

// // function MapDriver(props) {
   


    
// //     return (
// //         <MapContainer center={[25.7784,84.7515]} zoom={12}>
// //             <TileLayer
// //                 attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
// //                 url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
// //             />
// //             {features.map((park) => (
// //                 <Marker
// //                     key={park.properties.PARK_ID}
// //                     position={park.geometry.coordinates}
// //                     icon={park.type === "Red" ? redIcon : park.type==="Orange" ? orangeIcon: greenIcon }
// //                 >
// //                 <Popup
// //                      position={park.geometry.coordinates}
// //                 >
// //                     <div>
// //                         <h6>{park.properties.NAME} </h6>
// //                         <p>{park.properties.DESCRIPTIO}</p>
// //                     </div>
// //                 </Popup>
// //             </Marker>
// //              ))}
// //         </MapContainer>
// //     );
// // }

// // export default MapDriver;


import React, { useState, useEffect } from "react";
import { GoogleMap , useJsApiLoader, LoadScript, Marker, InfoWindow } from "@react-google-maps/api";

const mapStyles = [
    {
      "elementType": "geometry",
      "stylers": [
        { "color": "#ffffff" }  // Light brown background
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        { "color": "#000000" }  // Dark brown label text
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        { "color": "#ffffff" }  // Label text stroke
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        { "color": "#c9c9c9" }  // Water color
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        { "color": "#000000" }  // Road color
      ]
    }
  ];
const features = [
    {
      "type": "Green",
      "properties": {
        "PARK_ID": 960,
        "NAME": "Lodhi Colony",
        "DESCRIPTIO": "Flat asphalt surface, 5 components",
      },
      "geometry": {
        "type": "Point",
        "coordinates": [25.765150, 84.783840],
      },
    },
    {
      "type": "Red",
      "properties": {
        "PARK_ID": 1219,
        "NAME": "Rampur",
        "DESCRIPTIO": "Status: No connection",
      },
      "geometry": {
        "type": "Point",
        "coordinates": [25.7643, 84.904610],
      },
    },
    {
      "type": "Orange",
      "properties": {
        "PARK_ID": 1157,
        "NAME": "Naini Chhapra",
        "DESCRIPTIO": "Status: Healthy",
      },
      "geometry": {
        "type": "Point",
        "coordinates": [25.765150, 84.783840],
      },
    },
  ];
  
  // Custom icons for markers
  const icons = {
    Red: "/redssl.png",
    Green: "/greenssl.png",
    Orange: "/greenssl.png",
  };
  
  const mapContainerStyle = {
    width: "100%",
    height: "70vh",
    
  };
  
  const center = {
    lat: 25.7784,
    lng: 84.7515,
  };

function MapDriver(props) {
  const [selectedPark, setSelectedPark] = useState(null);
  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY
  });

  if (loadError) {
    console.error("Error loading Google Maps API:", loadError);
    return <div>Error loading map</div>;
  }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  if(isLoaded){
    return (
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            zoom={12}
            center={center}
            // options={{ styles: mapStyles, mapTypeId: "roadmap" }}
          >
            {features.map((park) => (
              <Marker
                key={park.properties.PARK_ID}
                position={{
                    lat: park.geometry.coordinates[0],
                    lng: park.geometry.coordinates[1],
                  }}
                icon={{
                    url: icons[park.type], // Your icon URL
                    scaledSize: new window.google.maps.Size(30, 30), // Fixed width and height
                  }}
                
                onClick={() => setSelectedPark(park)}
              />
            ))}
    
            {selectedPark && (
              <InfoWindow
              position={{
                lat: selectedPark.geometry.coordinates[0],
                lng: selectedPark.geometry.coordinates[1],
              }}
                onCloseClick={() => setSelectedPark(null)}
              >
                <div>
                  <h6>{selectedPark.properties.NAME}</h6>
                  <p>{selectedPark.properties.DESCRIPTIO}</p>
                </div>
              </InfoWindow>
            )}
          </GoogleMap>
      );
  }

  else {
    return <div>Loading</div>
  }


}

export default MapDriver;
