import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

function createData(id, name, calories, fat, carbs, protein, price) {
  return {
    id,
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      {
        module: "SSL",
        tag :"94cd1932-2c33", 
        manufacturer :"Havells",
        specs :"24 Watt", 
        dateOfReplacement :"2022-08-12", 
        reasonOfReplacement : "Faulty"
      },
      {
        module: "PV",
        tag :"4885e7b3-b9e8", 
        manufacturer :"Havells",
        specs :"120 Watt", 
        dateOfReplacement :"2022-08-12", 
        reasonOfReplacement : "Faulty"
      },
      {
        module: "Battery",
        tag :"231a2765-076f", 
        manufacturer :"Havells",
        specs :"54 AH", 
        dateOfReplacement :"NA", 
        reasonOfReplacement : "NA"
      },
      
    ],
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
console.log("rowssssssssssss",row);
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.id}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="center">{row.calories}</TableCell>
        <TableCell align="center">{row.fat}</TableCell>
        <TableCell align="center">{row.carbs}</TableCell>
        <TableCell align="center">{row.protein}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Module</TableCell>
                    <TableCell>Tag No.</TableCell>
                    <TableCell>Manufacturer</TableCell>
                    <TableCell align="center">Specs</TableCell>
                    <TableCell align="center">Date of replacement</TableCell>
                    <TableCell align="center">Reason of replacement</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history.map((historyRow) => (
                    <TableRow key={historyRow.date}>
                      <TableCell component="th" scope="row">
                        {historyRow.module}
                      </TableCell>
                      <TableCell>{historyRow.tag}</TableCell>
                      <TableCell align="center">{historyRow.manufacturer}</TableCell>
                      <TableCell align="center">
                        {historyRow.specs}
                      </TableCell>
                      <TableCell align="center">{historyRow.dateOfReplacement}</TableCell>
                      <TableCell align="center">{historyRow.reasonOfReplacement}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

const rows = [
  createData("1", "BIHSARSADNAI1201", "2022-05-01", "D. Kumar", "Saran, Sadar, Naini, ward 1", "Healthy"),
  createData("2","BIHSARSADNAI1202", "2022-06-03", "J. Kumar", "Saran, Sadar, Naini, ward 2", "Unhealthy"),
  createData("3","BIHSARSADNAI1203", "2022-06-05", "A. Kumar", "Saran, Sadar, Naini, ward 3", "Unhealthy"),
  createData("4","BIHSARSADNAI1204", "2022-06-07", "F. Kumar", "Saran, Sadar, Naini, ward 4", "Unhealthy"),
  createData("5","BIHSARSADNAI1205", "2022-06-08", "S. Kumar", "Saran, Sadar, Naini, ward 5", "Unhealthy"),
];

export default function CollapsibleTable() {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>S.no.</TableCell>
            <TableCell>Device ID</TableCell>
            <TableCell align="center">Date Of Installation</TableCell>
            <TableCell align="center">Owner</TableCell>

            <TableCell align="center">Location</TableCell>
            <TableCell align="center">Health</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.name} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
