import React from 'react'

function SystemPerformance() {
  return (
    <>
    <h4 className='colorssl'>SystemPerformance :-</h4>
    <div className='image-container'>
        <img className='' src='/images/network-1.jpg'></img>
    </div>
    <div className='row'>
<div className='col-md-6'>
<img className='' src='/images/network-2.jpg'></img>
</div>
<div className='col-md-6'>
<img className='' src='/images/network-3.jpg'></img>
</div>
    </div>
    </>
  )
}

export default SystemPerformance